<script setup lang="ts">
import type { Toast } from '@/composables/useToast'

const props = withDefaults(defineProps<{ timeout?: number }>(), {
  timeout: 2000,
})

const { hasToast, popToast } = useToast()
const notificationVisibility = ref(false)
const notificationMessage = ref('')

const notificationColor = ref<Toast['color']>('none')
watch(hasToast, (hasToast) => {
  if (hasToast) {
    const toast = popToast()
    if (!toast) return

    notificationVisibility.value = true
    notificationMessage.value = toast.message
    notificationColor.value = toast.color

    setTimeout(() => {
      notificationVisibility.value = false
    }, props.timeout)
  }
})

const classes = computed(() => {
  return `is-${notificationColor.value}`
})
</script>

<template>
  <div role="status">
    <Transition>
      <div
        v-if="notificationVisibility"
        class="notification overlay"
        :class="classes"
        @click="notificationVisibility = false"
      >
        <div class="is-flex">
          <p class="is-flex is-flex-grow-1 is-justify-content-center">
            <span class="notification-message">
              {{ notificationMessage }}
            </span>
          </p>
          <button class="delete is-justify-content-end"></button>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.overlay {
  position: absolute;
  width: 100%;
  z-index: 1000;
  opacity: 0.8;
}

.notification-message {
  font-weight: bold;
}

.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
