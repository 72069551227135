import type { User as SupabaseUser } from '@supabase/supabase-js'
import { getUserByAccountId } from '~/repositories/userRepository'
import type { UserWithEmailSettings } from '~/entities/user'

const convertToCurrentUser = (user: UserWithEmailSettings) => {
  return {
    ...user,
    canEditOrganization: user.role === 'ORGANIZATION_ADMIN',
    canEditLocation: user.role === 'ORGANIZATION_ADMIN' || user.role === 'LOCATION_ADMIN',
    enabledMyPageNotifications:
      user.user_email_settings?.enabled_my_page_notifications ?? user.enabled_email_notification,
    enabledAssignedTicketNotifications: user.user_email_settings?.enabled_assigned_ticket_notifications ?? true,
  }
}

const recoverUser =
  (
    supabaseUser: Ref<SupabaseUser | null>,
    userState: Ref<UserWithEmailSettings | null>,
    isPendingState: Ref<boolean>
  ) =>
  async () => {
    if (supabaseUser.value) {
      const { user_accounts: userAccounts, ...user } = await getUserByAccountId(supabaseUser.value.id)
      if (userAccounts.length !== 1) throw new Error('invalid user account data')

      userState.value = user
      isPendingState.value = userAccounts[0].is_pending
    }
  }

export const useCurrentUser = () => {
  const supabaseUser = useSupabaseUser()
  const user = useState<UserWithEmailSettings | null>('user')
  const isPending = useState<boolean>('isPending', () => false)

  const currentUser = computed(() => {
    return user.value ? convertToCurrentUser(user.value) : null
  })

  return {
    currentUser,
    isPending,
    recoverUser: recoverUser(supabaseUser, user, isPending),
    updateCurrentUser: (updatedUser: UserWithEmailSettings | null) => (user.value = updatedUser),
  }
}
