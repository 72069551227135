export const COLOR = {
  green5: '#e7f8e9',
  green10: '#bee8c3',
  green50: '#037d12',
  green70: '#015b0d',

  red5: '#fff3f5',
  red50: '#de1f3e',
  red70: '#ac112a',

  gray5: '#f3f2f2',
  gray10: '#e6e6e6',
  gray20: '#cecdcd',
  gray60: '#6b6969',

  white: '#ffffff',

  neutral0: '#ffffff',
  neutral5: '#f2f3f3',
  neutral10: '#e6e6e6',
  neutral40: '#9b9c9c',
  neutral60: '#696b6a',
  neutral80: '#373938',
}

export const STATUS_COLOR = {
  OK: {
    COLOR: '#037d12',
    BACKGROUND_COLOR: '#e7f8e9',
  },
  NG: {
    COLOR: '#de1f3e',
    BACKGROUND_COLOR: '#fff7f9',
  },
  SKIPPED: {
    COLOR: '#373938',
    BACKGROUND_COLOR: '#f2f3f3',
  },
} as const satisfies Record<string, { COLOR: string; BACKGROUND_COLOR: string }>

export const BUTTON_COLOR = {
  none: {
    COLOR: COLOR.gray60,
    BACKGROUND_COLOR: COLOR.white,
    HOVERED_BACKGROUND_COLOR: COLOR.gray5,
    BORDER_COLOR: COLOR.gray60,
    HOVERED_BORDER_COLOR: COLOR.gray60,
  },
  primary: {
    COLOR: COLOR.white,
    BACKGROUND_COLOR: COLOR.green50,
    HOVERED_BACKGROUND_COLOR: COLOR.green70,
    BORDER_COLOR: COLOR.green50,
    HOVERED_BORDER_COLOR: COLOR.green70,
  },
  'primary-light': {
    COLOR: COLOR.green50,
    BACKGROUND_COLOR: COLOR.white,
    HOVERED_BACKGROUND_COLOR: COLOR.green5,
    BORDER_COLOR: COLOR.green50,
    HOVERED_BORDER_COLOR: COLOR.green50,
  },
  danger: {
    COLOR: COLOR.white,
    BACKGROUND_COLOR: COLOR.red50,
    HOVERED_BACKGROUND_COLOR: COLOR.red70,
    BORDER_COLOR: COLOR.red50,
    HOVERED_BORDER_COLOR: COLOR.red70,
  },
  'danger-light': {
    COLOR: COLOR.red50,
    BACKGROUND_COLOR: COLOR.white,
    HOVERED_BACKGROUND_COLOR: COLOR.red5,
    BORDER_COLOR: COLOR.red50,
    HOVERED_BORDER_COLOR: COLOR.red50,
  },
  secondary: {
    COLOR: COLOR.gray60,
    BACKGROUND_COLOR: COLOR.white,
    HOVERED_BACKGROUND_COLOR: COLOR.gray5,
    BORDER_COLOR: COLOR.gray20,
    HOVERED_BORDER_COLOR: COLOR.gray60,
  },
}

export const TEXT_BUTTON_COLOR = {
  none: {
    COLOR: COLOR.gray60,
    HOVERED_BACKGROUND_COLOR: COLOR.gray5,
  },
  primary: {
    COLOR: COLOR.green50,
    HOVERED_BACKGROUND_COLOR: COLOR.green5,
  },
}
