import revive_payload_client_3nLCIfoIgo from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.12_encoding@0.1.13_eslint@8.57.1_ioredis@_hbuz2hgmgph3aypx3exwwun274/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_n0pcKMoQjM from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.12_encoding@0.1.13_eslint@8.57.1_ioredis@_hbuz2hgmgph3aypx3exwwun274/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_WW8qHJI7Dd from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.12_encoding@0.1.13_eslint@8.57.1_ioredis@_hbuz2hgmgph3aypx3exwwun274/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ZNHBrYie7z from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.12_encoding@0.1.13_eslint@8.57.1_ioredis@_hbuz2hgmgph3aypx3exwwun274/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_XWJBeDHAAU from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.12_encoding@0.1.13_eslint@8.57.1_ioredis@_hbuz2hgmgph3aypx3exwwun274/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ih0fCH0bKU from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.12_encoding@0.1.13_eslint@8.57.1_ioredis@_hbuz2hgmgph3aypx3exwwun274/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_LyoQUQqEnf from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.12_encoding@0.1.13_eslint@8.57.1_ioredis@_hbuz2hgmgph3aypx3exwwun274/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/checkup/checkup/admin/.nuxt/components.plugin.mjs";
import prefetch_client_e9Q5hNJmNg from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.12_encoding@0.1.13_eslint@8.57.1_ioredis@_hbuz2hgmgph3aypx3exwwun274/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import supabase_client_Vu6b6nytQn from "/home/runner/work/checkup/checkup/admin/src/plugins/supabase.client.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/checkup/checkup/admin/src/plugins/sentry.client.ts";
import dayjs_client_A2bzKURDlV from "/home/runner/work/checkup/checkup/admin/src/plugins/dayjs.client.ts";
import gtag_6Z9lu4VVCL from "/home/runner/work/checkup/checkup/admin/src/plugins/gtag.ts";
import i18n_YCWKwfVXF6 from "/home/runner/work/checkup/checkup/admin/src/plugins/i18n.ts";
import qrcode_client_gU8ufMWnQ5 from "/home/runner/work/checkup/checkup/admin/src/plugins/qrcode.client.ts";
import vuedraggable_ajhfMTWOKD from "/home/runner/work/checkup/checkup/admin/src/plugins/vuedraggable.ts";
export default [
  revive_payload_client_3nLCIfoIgo,
  unhead_n0pcKMoQjM,
  router_WW8qHJI7Dd,
  payload_client_ZNHBrYie7z,
  navigation_repaint_client_XWJBeDHAAU,
  check_outdated_build_client_ih0fCH0bKU,
  chunk_reload_client_LyoQUQqEnf,
  components_plugin_KR1HBZs4kY,
  prefetch_client_e9Q5hNJmNg,
  supabase_client_Vu6b6nytQn,
  sentry_client_KAXFuL2wum,
  dayjs_client_A2bzKURDlV,
  gtag_6Z9lu4VVCL,
  i18n_YCWKwfVXF6,
  qrcode_client_gU8ufMWnQ5,
  vuedraggable_ajhfMTWOKD
]