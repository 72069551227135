export default defineNuxtRouteMiddleware(async (to, _) => {
  const { recheckSession } = useAuth()
  await recheckSession()

  const supabaseUser = useSupabaseUser()

  // 未ログイン状態の場合はlogin画面に転送
  if (!supabaseUser.value && to.name !== 'login') {
    return navigateTo({
      name: 'login',
      query: {
        redirect: to.path,
        redirectQuery: JSON.stringify(to.query),
      },
    })
  }

  // ログイン状態の場合
  if (supabaseUser.value) {
    // その他stateを必要に応じて復元
    const { currentUser, isPending, recoverUser } = useCurrentUser()
    const { organization, recoverOrganization } = useOrganization()
    const { currentLocationId, recoverLocationId } = useCurrentLocation()

    if (!currentUser.value) {
      await recoverUser()
    }

    if (!organization.value) {
      await recoverOrganization()
    }

    // 初期セットアップ未実施の場合はセットアップ画面へ転送
    if (isPending.value) {
      if (to.name === 'organizationId-myself-setup') return

      return navigateTo({
        name: 'organizationId-myself-setup',
        params: { organizationId: organization.value?.id },
      })
    }

    if (!currentLocationId.value) {
      const userLocationId = currentUser.value?.location_id ?? null
      await recoverLocationId(userLocationId)
    }
  }
})
